<template>
  <v-flex xs12 class="cra-list">
    <v-data-table
      v-if="cras"
      :items="cras"
      :headers="filteredHeaders"
      sort-by="id"
      sort-desc
      no-data-text="Aucun CRA n'a encore été créé"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td>{{ getMonth(props.item) }}</td>
          <td v-if="showResource && $store.getters.isCompany">{{ props.item.resource.name }}</td>
          <td>{{ props.item.client ? props.item.client.name : '[Client non défini]' }}</td>
          <td v-if="showValid" class="justify-center px-0">
            <v-icon
              v-if="props.item.validated"
              class="validated"
            >
              mdi-check-circle-outline
            </v-icon>
          </td>
          <td class="justify-center px-0">
            <v-icon
              v-if="!props.item.validated"
              @click="downloadCra(props.item)"
            >
              mdi-table-headers-eye
            </v-icon>
            <v-icon
              v-else
              @click="downloadCra(props.item)"
            >
              mdi-cloud-download
            </v-icon>
          </td>
          <td class="justify-center px-0">
            <v-icon
              v-if="canBeDeleted(props.item)"
              @click="deleteItem(props.item)"
            >
              mdi-delete
            </v-icon>
          </td>
          <td class="justify-center px-0">
            <v-icon
              v-if="props.item.resource.type === 2"
              @click="sendRelaunch(props.item)"
            >
              mdi-calendar-alert
            </v-icon>
          </td>
          <td v-if="showBilling">
            <el-button
              v-if="canBeBilled(props.item)"
              size="small"
              type="primary"
              :disabled="!props.item.client || !props.item.mission.tjm"
              @click="createBill(props.item)"
            >
              Créer la facture
            </el-button>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import _filter from 'lodash/filter'
import moment from 'moment'
import { apiDelete } from '@/assets/js/helpers/apiQuery'

export default {
  props: {
    cras: {
      type: Array,
      default: () => []
    },
    showResource: {
      type: Boolean,
      default: true
    },
    showValid: {
      type: Boolean,
      default: true
    },
    showBilling: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    canBeDeleted (item) {
      if (item.hasBill) {
        return false
      }
      return true
    },
    getMonth (cra) {
      return moment(cra.refMonth).format('MMMM YYYY')
    },
    async deleteItem (cra) {
      if (!cra.validated || window.confirm('Ce rapport est validé, êtes-vous sur de vouloir le supprimer?')) {
        await apiDelete(`/cras/${cra.id}`)
        this.$emit('refresh')
      }
    },
    downloadCra (cra) {
      if (!cra.validated) {
        return
      } else {
        window.open(`${process.env.VUE_APP_API_BASE}/cras/${cra.id}/pdf?auth_token=${this.$store.state.sessionKey}&domain=${location.host}`)
      }
    },
    createBill (item) {
      this.$emit('generate-bill', item)
    },
    canBeBilled (item) {
      return item.validated || item.mission.generateInvoiceBeforeCRAValidation
    },
    sendRelaunch (item) {
      console.log(item)
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Mois', align: 'left', sortable: false, value: 'month', visible: true },
        { sortable: false, text: 'Resource', value: 'resource', visible: this.showResource && this.$store.getters.isCompany },
        { sortable: false, text: 'Client', value: 'client', visible: true },
        { sortable: false, value: 'valid', visible: this.showValid },
        { sortable: false, value: 'view', visible: true },
        { sortable: false, value: 'delete', visible: true },
        { sortable: false, value: 'relauch', visible: true },
        { sortable: false, value: 'billing', visible: this.showBilling }
      ]
    },
    filteredHeaders () {
      return _filter(this.headers, header => header.visible)
    }
  }
}
</script>

<style lang="scss">
  .cra-list {
    .theme--light {
      &.validated {
        color: green;
      }
    }
  }
</style>
